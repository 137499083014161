<div class="card">

    <div class="{{dashboardState === true ? 'card-title btn-move-card' : 'card-title '}}">
      <span class="title">
        <span class="title-text">
          {{chartTypeValue}}
        </span>
      </span>
      
      <ng-template #templateIconReference>
              <factoryflow-pop-over-llangsong 
              (iconOptionClick)="openSetting()"
              (iconFullScreenClick)="dashboardActionDataDelivery('expansion')" 
              (iconSettingClick)="openSetting()"
              (iconChangeClick)="dashboardActionDataDelivery('change')"
              (iconCopyClick)="dashboardActionDataDelivery('copy')"
              (iconPasteClick)="dashboardActionDataDelivery('paste')"
              (iconDeleteClick)="dashboardActionDataDelivery('remove')"
              (iconExportClick)="null" [iconDisableConfig]="iconDisableConfig"
              [dashboardState]="dashboardState" [cardExtensionState]="cardExtensionState"></factoryflow-pop-over-llangsong>
      </ng-template>
      <ng-container *ngIf="dashboardState; then templateIconReference"></ng-container>
      <ng-container *ngIf="!dashboardState; then templateIconReference"></ng-container>
    </div>

    <div class="card-content">
      <factoryflow-content-output *ngIf="chartType==='contentOutput'" [llangsongData]="llangsongData"></factoryflow-content-output>
      <factoryflow-product-output *ngIf="chartType === 'productOutput'" [llangsongData]="llangsongData"></factoryflow-product-output>
      <factoryflow-region-order *ngIf="chartType === 'regionOrder'" [llangsongData]="llangsongData"></factoryflow-region-order>
      <factoryflow-shipping-amount *ngIf="chartType === 'shippingAmount'" [llangsongData]="llangsongData"></factoryflow-shipping-amount>
    </div>
    <div *ngIf="isSetting" class="card-setting" >
      <div class="top-area">
        <button *ngIf="dashboardState" class="save-btn" (click)="saveData()"></button>
        <button class="close-btn" (click)="closeSetting()"></button>
      </div>
      <div class="option-wrap">
        <select *ngIf="dashboardState" [value]="chartType" (change)="setChartType($event.target)">
          <option value="">-</option>
          <option value="contentOutput">품목별 생산량</option>
          <option value="productOutput">생산량</option>
          <option value="regionOrder">지역별 주문수량</option>
          <option value="shippingAmount">출하량</option>
        </select>
          <dp-date-picker *ngIf="!dashboardState" class="datepicker-wrap"
          #productDatePickerCom
          id="productDatePicker"
          [(ngModel)]="date"
          [mode]="'day'"
          [config]="config"
          required="true"
          (onChange)="onDateChange($event)"
          [theme]="'dp-material dp-main'"
          >
        </dp-date-picker>
      
      </div>
    </div>
</div>