import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { User } from './user';

@Injectable({ providedIn: 'root' })
export class AuthService {

    private currentUserSubject: BehaviorSubject<User|null>;
    public currentUser: Observable<User|null>;

    constructor() {
        this.currentUserSubject = new BehaviorSubject<User|null>(JSON.parse(sessionStorage.getItem('user')as string));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User|null {
        this.currentUserSubject = new BehaviorSubject<User|null>(JSON.parse(sessionStorage.getItem('user') as string));
        return this.currentUserSubject.value;
    }

    setUser(user: User|null) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        sessionStorage.setItem('user', JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
    }

    public getCurrentUserLang(){
      return sessionStorage.getItem('lang');
    }

    setLanguage(value:string){
        sessionStorage.setItem('lang', value);
    }

    removeLanguage() { sessionStorage.removeItem('lang'); }

    removeUser() {
        // remove user from local storage to log user out
        sessionStorage.removeItem('user');
        this.currentUserSubject.next(null);
    }
}
