import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import * as Highcharts from 'highcharts';
import { LlangsongService } from '../../../services/llangsong.service';
import { darkTheme, whiteTheme } from '../production-status-day/const/chart.themes';
@Component({
  selector: 'factoryflow-content-output',
  templateUrl: './content-output.component.html',
  styleUrls: ['./content-output.component.scss']
})
export class ContentOutputComponent implements OnInit ,AfterViewInit, OnChanges,OnDestroy {
  @Input() llangsongData;
  @ViewChild('contentChart') contentChart : ElementRef;
  options;
  chart;
  chartData;
  themeSub;
  constructor(private llansongService : LlangsongService) { }

  ngOnInit(): void {
    sessionStorage.getItem('theme') === 'dark' ? Highcharts.setOptions(darkTheme) : Highcharts.setOptions(whiteTheme)
    this.themeSub = this.llansongService.themeSubject.subscribe({
      next: (value)=>{
        console.log(value);
        if(value==='dark'){
          Highcharts.setOptions(darkTheme)
        }else{
          Highcharts.setOptions(whiteTheme)
        }
        this.chart = Highcharts.chart(this.contentChart.nativeElement, this.options);
      }
    })
  }
  ngOnChanges(changes: SimpleChanges): void {
      if(changes.llangsongData.currentValue){
        this.chartData = changes.llangsongData.currentValue.productByItemList
          this.updateChartData();
      }
  }
  ngAfterViewInit(): void {
      this.initChartOption()
  }
  ngOnDestroy(): void {
      this.themeSub.unsubscribe();
  }
  updateChartData(){
    const seriesData = this.chartData.map((item : any) => ({name:item.itemName,y:Number(item.prodPercent)}))
    this.options.series[0].data = seriesData;
    this.chart.series[0].update({ data: seriesData }, false);
    this.chart.redraw();
  }
  initChartOption() {
    this.options = {
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      chart: {
        spacingTop: 0,
        spacingBottom: 0,
        spacingLeft: 0,
        spacingRight: 0,
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        style: {
          fontFamily: 'NotoSansCJKkr, NotoSansKR, sans-serif'
        }
      },
      title: { text: '' },
      yAxis: {
        labels: {
          allowOverlap: true,
          format: "{value:f}",
        }
      },
      legend: {
        align: 'center',
        y: -20,
        enabled: true,
        itemStyle: {
          fontSize: '14px',
          fontWeight: '400',
        }
      },
      navigation:{
        buttonOptions:{
          enabled:false
        }
      },
      credits: {
        enabled: false
      },
      tooltip: {
        formatter: function () {
          const self : any = this
          return '<b>' + self.key+' : ' + '</b>' + self.y + 'EA';
        }
      },
      plotOptions: {
        pie: {
          borderWidth: 1,
          dataLabels: {
            crop: false,
            enabled: true,
            format: '<span style="font-size:18px; color: #ffffff; text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5); font-weight: 500;">{point.percentage:,.0f}%</span>',
            distance: '-40%',
            style: {
              textOutline: '#000000',

            }
          },
          showInLegend: true,
          allowPointSelect: true,
        }
      },
      series: [{
        type: 'pie',
        size: '90%',
        data: []
      }]
    }

    this.chart = Highcharts.chart(this.contentChart.nativeElement, this.options);
  }
}
