// SKU 가 1개 일때
export const type1 = {
    "data":{
       "datetime":"2023-11-21T12:13:14+09:00",
       "resultCd":"0000",
       "resultMsg":"success.",
       "production":{
         "lineName": "LINE 5",
         "todayRanking": "6/16",
         "weeklyRanking": "4/19",
         "monthlyRanking": "6/19",
         "items": [
           {
             "order": "TM0065198",
             "sku": "01402539-AT0-44641",
             "imgUrl": "https://shop1.daumcdn.net/thumb/R500x500/ddffdd.png",
             "color": "QBPHT",
             "orderQTY": 2420,
             "accQTY": 243,
             "pendingQTY": 2177,
             "packQTY": 0,
             "tempPackQTY": 243,
             "dailyOutput": 170,
             "hourlyTarget": 53,
             "targetPercent": 85,
             "targetDiff": -30,
             "slots": [
               {
                 "time": "07:30~08:30",
                 "target": 50,
                 "actual": 40
               },
               {
                 "time": "08:30~09:30",
                 "target": 50,
                 "actual": 50
               },
               {
                 "time": "09:30~10:30",
                 "target": 50,
                 "actual": 50
               },
               {
                 "time": "10:30~11:30",
                 "target": 50,
                 "actual": 30
               },
               {
                 "time": "12:30~13:30",
                 "target": 50,
                 "actual": 0
               },
               {
                 "time": "13:30~14:30",
                 "target": 50,
                 "actual": 0
               },
               {
                 "time": "14:30~15:30",
                 "target": 50,
                 "actual": 0
               },
               {
                 "time": "15:30~16:30",
                 "target": 33,
                 "actual": 0
               },
               {
                 "time": "16:30~17:30",
                 "target": 50,
                 "actual": 0
               },
               {
                 "time": "17:30~18:30",
                 "target": 50,
                 "actual": 0
               },
               {
                 "time": "18:30~19:30",
                 "target": 50,
                 "actual": 0
               },
               {
                 "time": "19:30~20:30",
                 "target": 50,
                 "actual": 0
               }
             ]
           }
         ]
       }
    }
 }
 // SKU 가 2개 이상일때
 export const type2 = {
    "data":{
       "datetime":"2023-08-02T12:13:13+09:00",
       "resultCd":"0000",
       "resultMsg":"success.",
       "production":{
         "lineName": "LINE 3",
         "todayRanking": "2/19",
         "weeklyRanking": "2/19",
         "monthlyRanking": "1/19",
         "items": [
           {
             "order": "TM0063326",
             "sku": "01407968-AJ0-37758",
             "imgUrl": "https://shop1.daumcdn.net/thumb/R500x500/ddffdd.png",
             "color": "B4/UA",
             "orderQTY": 1100,
             "accQTY": 1057,
             "pendingQTY": 43,
             "packQTY": 0,
             "tempPackQTY": 1057,
             "dailyOutput": 20,
             "hourlyTarget": 40,
             "targetPercent": 90,
             "targetDiff": -4,
             "slots": []
           },
           {
             "order": "TM0063326",
             "sku": "01407968-AJ1-49993",
             "imgUrl": "https://shop1.daumcdn.net/thumb/R500x500/ddffdd.png",
             "color": "B4UC8",
             "orderQTY": 845,
             "accQTY": 811,
             "pendingQTY": 34,
             "packQTY": 403,
             "tempPackQTY": 408,
             "dailyOutput": 16,
             "hourlyTarget": 40,
             "targetPercent": 95,
             "targetDiff": -25,
             "slots": []
           }
         ]
       }
    }
 }