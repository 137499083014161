import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopOverComponent } from './components/pop-over/pop-over.component';
import { ProductOutputComponent } from './components/cards/product-output/product-output.component';
import { ContentOutputComponent } from './components/cards/content-output/content-output.component';
import { RegionOrderComponent } from './components/cards/region-order/region-order.component';
import { ShippingAmountComponent } from './components/cards/shipping-amount/shipping-amount.component';
import { ProductionStatusDayComponent } from './components/cards/production-status-day/production-status-day.component';
import { DpDatePickerModule } from 'ng2-date-picker';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  imports: [CommonModule,DpDatePickerModule,FormsModule,HttpClientModule],
  declarations: [
    PopOverComponent,
    ProductOutputComponent,
    ContentOutputComponent,
    RegionOrderComponent,
    ShippingAmountComponent,
    ProductionStatusDayComponent
  ],
  exports: [
    ProductionStatusDayComponent
  ]
})
export class LlangsongUiModule {}
