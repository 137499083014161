import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProductionResType } from '../types/type';

@Injectable({
  providedIn: 'root'
})
export class SimoneService {
  constructor(private http : HttpClient) { 
  }
  getProductData(lineName : string){
    const date = '20231121'
    return this.http.get<ProductionResType>(`/api/v1/product-line-status/TG1-1/${lineName}/${date}`)
  }
}
