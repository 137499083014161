/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Routes, RouterModule } from '@angular/router';
import { RoutesConfig } from './app.config';
import { ɵm } from '@wildstorm/wildsea-ui'
import { MainComponent } from './main/main.component';
import { AuthGuard } from './guard/auth.guard';
import { ComponentInfoConfig } from './app.config';
const appRoutes: Routes = [
  {
      path: '', component: MainComponent, canActivate: [AuthGuard],
      children: [
        { path: 'wildgarden', loadChildren: () => import('@wildstorm/wildgarden-ui').then((m) => m.WildgardenUiModule), data: RoutesConfig },
        { path: 'asterisk', loadChildren: () => import('@wildstorm/asterisk-ui').then(m => m.AsteriskUiModule)},
        // { path: 'zephyr/:index', loadChildren: () => import('@wildstorm/zephyr-ui').then(m => m.ZephyrUiModule)},
        // { path: 'crosswind/:index', loadChildren: () => import('@wildstorm/crosswind-ui').then(m => m.CrosswindUiModule)},
        { path: 'rainbow/:index', loadChildren: () => import('@wildstorm/rainbow-ui').then(m => m.RainbowUiModule)},
        { path: 'kpi', loadChildren: () => import('@wildstorm/kpi-ui').then(m => m.KpiUiModule)},
        // { path: 'schedule', loadChildren: () => import('@wildstorm/schedule-management-ui').then(m => m.ScheduleManagementUiModule)},
        { path: 'honeycomb/:index', loadChildren: () => import('@wildstorm/honeycomb-ui').then(m => m.HoneycombUiModule), data: {componentInfo: ComponentInfoConfig}},

      ]
  },
  { path: 'login', component: ɵm },
  { path: '**', redirectTo: '' }
];

export const AppRouting = RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledNonBlocking', onSameUrlNavigation: 'reload' });
