import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IconDisableConfig } from '../../pop-over/pop-over.component';
import {DatePickerComponent, IDatePickerConfig} from "ng2-date-picker";
import { DEF_CONF } from './const/const';
import * as moment from 'moment';
import { LlangsongService } from '../../../services/llangsong.service';
@Component({
  selector: 'factoryflow-production-status-day',
  templateUrl: './production-status-day.component.html',
  styleUrls: ['./production-status-day.component.scss']
})
export class ProductionStatusDayComponent implements OnInit,AfterViewInit {
  /** Card Data */
  @Input() data: any['data'];

  /** Card Event Emitter */
  @Output() dashboardTypeAction = new EventEmitter<any>();

  /** 대시보드 상태 값 */
  @Input() dashboardState: boolean;
  @Input() cardExtensionState: boolean;
  @ViewChild('dropList') dropListComp: ElementRef;
  /** Card Function */
  iconDisableConfig: IconDisableConfig = {
      isOption: true,            // 옵션
      isSetting: true,            // 설정
      isExcelExport: false         // 엑셀
  };
      setdata = {
      request_count: 5,
      ack: 0,
      kind: 1,
      priority: 3,
  }
  @ViewChild('productDatePickerCom') productDatePickerCom!: DatePickerComponent;
  date;
  config: IDatePickerConfig = {
    ...DEF_CONF,
    locale: 'ko',
    format: 'YYYY-MM-DD',
    appendTo: undefined
  };
  chartType : 'contentOutput' | 'productOutput' | 'regionOrder' | 'shippingAmount' | '' = '';
  tempChartType : 'contentOutput' | 'productOutput' | 'regionOrder' | 'shippingAmount' | '' = '';
  isSetting = false;
  llangsongData;
  constructor(private llangsongService : LlangsongService) { }
  appendEl;
  ngOnInit(): void {
    console.log(this.data);
    if(this.data.setdata.chartType){
      this.chartType = this.data.setdata.chartType
      this.tempChartType = this.data.setdata.chartType
    }else{
      this.chartType = ''
      this.tempChartType = ''
    }
      this.date = moment(new Date())
    
      this.setLlangsongData();
  }
  ngAfterViewInit(): void {
    if(document.querySelector('#print-section')){
      this.appendEl = document.querySelector('#print-section') as HTMLDivElement
    }else{
      this.appendEl = undefined
    } 
    this.config.appendTo = this.appendEl
  }
  get chartTypeValue(){
    if(this.chartType==='contentOutput')return '품목별 생산량'
    if(this.chartType==='productOutput')return '생산량'
    if(this.chartType==='regionOrder')return '지역별 주문수량'
    if(this.chartType==='shippingAmount')return '출하량'
    return ''
  }
  setLlangsongData(){
    if(this.chartType !== '' && this.date){
    this.llangsongService.getLlangsongData(this.chartType,moment(this.date).subtract(1,'days').format('YYYYMMDD')).subscribe((res : any)=>{
      this.llangsongData = res.data;
      console.log(this.llangsongData);
      
      // this.llangsongData = res;
    })
  }
  }
  changeTheme(){
    console.log('changeTheme')
  }
  openSetting(){
    this.isSetting= true;
  }
  closeSetting(){
    this.isSetting= false;
    this.tempChartType = this.chartType;
    if(this.dashboardState){
      if(this.data.setdata.date){
        this.date = this.data.setdata.date
      }else{
        this.date = moment(new Date())
      }
    }
  }
  saveData(){
    this.chartType = this.tempChartType;
    this.setLlangsongData();
    this.dashboardTypeAction.emit({type:'save', parameter:{...this.data,setdata:{chartType:this.tempChartType}}})
    this.isSetting = false;
  }
  setChartType(target){
    this.tempChartType = target.value;
  }
  onDateChange(e){
    this.date = e;
    if(!this.dashboardState){
      this.setLlangsongData();
    }
  }
  dashboardActionDataDelivery(type) {
    this.dashboardTypeAction.emit({ type: type, parameter: this.data });
  }
}
