import { Component, OnInit ,Input , Output , EventEmitter , ViewChild ,ElementRef, OnChanges, SimpleChanges, AfterViewInit, AfterViewChecked, OnDestroy} from '@angular/core';
import { IconDisableConfig } from '../cards/pop-over/pop-over.component';
import { type1 , type2 } from '../../data';
import { ProductionResType, ProductionType, SlotType } from '../../types/type';
import { SimoneService } from '../../services/simone.service';
import { Subject, Subscription } from 'rxjs';
@Component({
  selector: 'factoryflow-production-line-status',
  templateUrl: './production-line-status.component.html',
  styleUrls: ['./production-line-status.component.scss']
})
export class ProductionLineStatusComponent implements OnInit ,OnDestroy{

  /** Card Data */
  @Input() data: any['data'];

  /** Card Event Emitter */
  @Output() dashboardTypeAction = new EventEmitter<any>();

  /** 대시보드 상태 값 */
  @Input() dashboardState: boolean;
  @Input() cardExtensionState: boolean;
  @ViewChild('dropList') dropListComp: ElementRef;
  /** Card Function */
  iconDisableConfig: IconDisableConfig = {
      isOption: true,            // 옵션
      isSetting: true,            // 설정
      isExcelExport: false         // 엑셀
  };
      setdata = {
      request_count: 5,
      ack: 0,
      kind: 1,
      priority: 3,
  }
tempData
skuData : ProductionType | undefined;
skuType : 1|2;
dashBoardColType : 'wide'|'short'
lastUpdated : number;
counter : 1|2 = 1
isSetting = false
curLine:string
constructor(private simoneService : SimoneService){

}
ngOnInit(): void {
  console.log(this.data);
  if(this.data.setdata.selectedLine){
  this.curLine = this.data.setdata.selectedLine
    this.simoneService.getProductData(this.curLine).subscribe({next:(val:ProductionResType)=>{
      this.setupData(val.data.production)
    }})
  }
  // this.setupData(type2.data.production)
  // this.simoneService.getProductData('LINE05').subscribe({next:(val:ProductionResType)=>{
  //   this.setupData(val.data.production)
  // }})
  // this.dataInterval = setInterval(()=>{
  //   if(this.counter===1){
  //     this.simoneService.getProductData('LINE03').subscribe({next:(val:ProductionResType)=>{
  //       this.setupData(val.data.production)
  //       this.counter = 2
  //     }})
  //   }else{
  //     this.simoneService.getProductData('LINE05').subscribe({next:(val:ProductionResType)=>{
  //       this.setupData(val.data.production)
  //       this.counter=1
  //     }})
  //   }

  // },60000)
  
}

// setdata에 정보를 담아서 저장함
saveData(selectedValue){
  this.dashboardTypeAction.emit({type:'save', parameter:{...this.data,setdata:{selectedLine : selectedValue,id:this.data.cardId}}})
}

setupData(data : ProductionType){
  this.skuData = data
  this.setSkuData(this.skuData)
  this.lastUpdated = Number(type1.data.datetime.split('T')[1].split('+')[0].split(':').splice(0,2).join(''))
  this.dashBoardColType = this.data.cols>2?'wide':'short'
}
ngOnDestroy(): void {
  console.log();
}
toNumber(numberText){
  return Number(numberText)
}
setSkuData(data :ProductionType){
  if(data.items.length===1){
    this.skuType=1
  }else{
    if(data.items.length!==0){
      this.skuType=2
    }
  }
  

}
setSlotData(data:ProductionType | undefined){
  if(data){

  if(this.skuType===1){
    if(this.dashBoardColType==='wide'){
      let tempSlotArr=data.items[0].slots.sort((a,b)=>{
        return Number(a.time.split(':')[0])-Number(b.time.split(':')[0])
      })
      return tempSlotArr
    }else if(this.dashBoardColType==='short' && this.skuData?.items[0].slots.length===12){
      this.skuData.items[0].slots=data.items[0].slots.sort((a,b)=>{
        return Number(a.time.split(':')[0])-Number(b.time.split(':')[0])
      })
      let tempSlotArr : SlotType[] = []
      for(let i = 0; i<data.items[0].slots.length; i+=2){
        let tempSlotItem : SlotType = {
          time:`${data.items[0].slots[i].time.split('~')[0]}~${data.items[0].slots[i+1].time.split('~')[1]}`,
          target:data.items[0].slots[i].target+data.items[0].slots[i+1].target,
          actual:data.items[0].slots[i].actual+data.items[0].slots[i+1].actual
        }
        tempSlotArr.push(tempSlotItem)
      }
      return tempSlotArr
    }
  }
}

  return null
}
setLineData(){
  this.isSetting = true;
}
closeSetting(){
  this.isSetting = false;
}
saveSetting(id:number){
  const selectedValue = (document.getElementById('select-line'+id) as HTMLSelectElement)?.value
  console.log(selectedValue);
  if(selectedValue!==''){
    this.saveData(selectedValue)
    this.curLine = selectedValue
    this.simoneService.getProductData(this.curLine).subscribe({next:(val:ProductionResType)=>{
      this.setupData(val.data.production)
    }})
  }
  this.closeSetting()
}
// setLocation() {
//     this.onSetInitValueForEngineering();
//     let dropList = this.dropListComp.nativeElement;
//     dropList.classList.add('mobile_on');
//     dropList.find('button').css('display', 'block');
// }
// onSetInitValueForEngineering() {
//   this.setValueFromId('fae_alarm_kind', this.setdata.kind);
//   this.setValueFromId('fae_alarm_priority', this.setdata.priority);
// }
// setValueFromId(id, value) {
// let element = document.getElementById(id) as HTMLInputElement;
// element.value = value
// }
dashboardActionDataDelivery(type) {
// if (type === 'excel') {
//     const parm = {
//         csvData: this.chart.getCSV(),
//         fileName: this.translateService.instant('event.alarm') + ' & ' + this.translateService.instant('event.event')
//     }
//     this.dashboardTypeAction.emit({ type: type, parameter: parm });
// }
    this.dashboardTypeAction.emit({ type: type, parameter: this.data });
}

}
