import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductionLineStatusComponent } from './components/production-line-status/production-line-status.component';
import { PopOverComponent } from './components/cards/pop-over/pop-over.component';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  imports: [CommonModule , HttpClientModule],
  exports:[ProductionLineStatusComponent,PopOverComponent],
  declarations: [
    ProductionLineStatusComponent,
    PopOverComponent
  ],
})
export class SimoneUiModule {}
