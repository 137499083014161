<div class="card">

  <div class="{{dashboardState === true ? 'card-title btn-move-card' : 'card-title '}}">
    <!-- 201204 Wayne 버튼 위치 수정 -->
    <span class="title">
      <span class="title-text">
        Production Line Status
      </span>
    </span>
    
    <ng-template #templateIconReference>
            <factoryflow-pop-over (iconOptionClick)="setLineData()"
            (iconFullScreenClick)="dashboardActionDataDelivery('expansion')" (iconSettingClick)="setLineData()"
            (iconChangeClick)="dashboardActionDataDelivery('change')"
            (iconCopyClick)="dashboardActionDataDelivery('copy')"
            (iconPasteClick)="dashboardActionDataDelivery('paste')"
            (iconDeleteClick)="dashboardActionDataDelivery('remove')"
            (iconExportClick)="null" [iconDisableConfig]="iconDisableConfig"
            [dashboardState]="dashboardState" [cardExtensionState]="cardExtensionState"></factoryflow-pop-over>
    </ng-template>
    <ng-container *ngIf="dashboardState; then templateIconReference"></ng-container>
    <ng-container *ngIf="!dashboardState; then templateIconReference"></ng-container>
  </div>
  <div class="card-content">
    <div class="rank-area">
        <p>{{skuData?.lineName}}</p>
        <div class="rank-tb-wrap">
            <div class="rank-tb" *ngFor="let item of [skuData?.todayRanking,skuData?.weeklyRanking,skuData?.monthlyRanking] let idx = index">
                <div class="rank-tb-title">
                    <p>{{idx===0?'금일 순위':idx===1?'주간 순위':'월간 순위'}}</p>
                    <p>{{idx===0?'TODAY RANKING':idx===1?'WEEKLY RANKING':'MONTHLY RANKING'}}</p>
                    <p>{{idx===0?'Xếp hạng ngày':idx===1?'Xếp hạng tuần':'"Xếp hạng tháng'}}</p>
                </div>
                <div class="rank-tb-content">
                    <div class="rank-text">
                        <p>{{item?item.split('/')[0]:''}}</p>
                        <p>{{item?"/"+ item.split('/')[1]:''}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
     <!-- Type 1 target percentage area -->
    <div class="time-target-area" *ngIf="skuType===1">
        <div class="time-target-tb" *ngFor="let item of setSlotData(skuData)">
            <div class="time">
                <p>{{item?.time}}</p>
            </div>
            <div class="target">
                <p>{{item?.target}}</p>
            </div>
            <div class="actual" [ngClass]="toNumber(item.time?item.time.split('~')[1].split(':').join(''):'')<lastUpdated?item.target>item.actual?'red':'':''">
                <p>{{item?.actual}}</p>
            </div>
        </div>
        <div class="target-percent-tb">
          <div class="target-percent-title">
            <p>TARGET %</p>
          </div>
          <div class="target-percent">
            <p>{{skuData?skuData.items[0].targetPercent+'%':''}}</p>
          </div>
          <div class="target-diff">
            <p>{{skuData?skuData.items[0].targetDiff:''}}</p>
          </div>
        </div>
    </div>
     <!-- Type 2 target percentage area -->
    <div class="time-target-area" *ngIf="skuType===2">
      <div class="target-percent-tb wide">
        <div class="target-percent-title">
          <p>TARGET %</p>
        </div>
        <div class="target-percent">
          <p>{{skuData?skuData.items[0].targetPercent+'%':''}}</p>
        </div>
        <div class="target-diff">
          <p>{{skuData?skuData.items[0].targetDiff:''}}</p>
        </div>
      </div>
    </div>
    <!-- col이 두개 이하일때 -->
    <div class="sku-info-container"*ngIf="dashBoardColType==='short'">
      <div class="sku-info-area" *ngFor="let item of skuData?.items">
        <div class="sku-info-tb-short order">
          <div class="sku-info-title">
            <p>ORDER</p>
          </div>
          <div class="sku-info-content">
            <p>{{item?.order}}</p>
          </div>
        </div>
        <div class="sku-info-tb-short sku">
          <div class="sku-info-title">
            <img class="sku-img" src="{{item?.imgUrl}}" alt="">
            <p>SKU</p>
          </div>
          <div class="sku-info-content">
            <p>{{item?.sku}}</p>
          </div>
        </div>
        <div class="sku-info-tb-short acc-qty">
          <div class="sku-info-title">
            <p>누적 QTY</p>
            <p></p>
          </div>
          <div class="sku-info-content">
            <p>{{item?.accQTY}}</p>
          </div>
        </div>
        <div class="sku-info-tb-short pending-qty">
          <div class="sku-info-title">
            <p>펜딩 QTY</p>
          </div>
          <div class="sku-info-content">
            <p>{{item?.pendingQTY}}</p>
          </div>
        </div>
        <div class="sku-info-tb-short pack-qty">
          <div class="sku-info-title">
            <p>포장 QTY</p>
          </div>
          <div class="sku-info-content">
            <p>{{item?.packQTY}}</p>
          </div>
        </div>
        <div class="sku-info-tb-short temp-pack-qty">
          <div class="sku-info-title">
            <p>가포장 QTY</p>
          </div>
          <div class="sku-info-content">
            <p>{{item?.tempPackQTY}}</p>
          </div>
        </div>
      </div>

    </div>
      <!-- col이 세개 이상일때 -->
    <div class="sku-info-container" *ngIf="dashBoardColType==='wide'">
      <div class="sku-info-area" *ngFor="let item of skuData?.items">
        <div class="sku-info-tb-wide order">
          <div class="sku-info-title">
            <p>ORDER</p>
          </div>
          <div class="sku-info-content">
            <p>{{item?.order}}</p>
          </div>
        </div>
        <div class="sku-info-tb-wide sku">
          <div class="sku-info-title">
            <img class="sku-img" src="{{item?.imgUrl}}" alt="">
            <p>SKU</p>
          </div>
          <div class="sku-info-content">
            <p>{{item?.sku}}</p>
          </div>
        </div>
        <div class="sku-info-tb-wide color">
          <div class="sku-info-title">
            <p>COLOR</p>
          </div>
          <div class="sku-info-content">
            <p>{{item?.color}}</p>
          </div>
        </div>
        <div class="sku-info-tb-wide order-qty">
          <div class="sku-info-title">
            <p>ORDER QTY</p>
          </div>
          <div class="sku-info-content">
            <p>{{item?.orderQTY}}</p>
          </div>
        </div>
        <div class="sku-info-tb-wide acc-qty">
          <div class="sku-info-title">
            <p>누적 QTY</p>
            <p></p>
          </div>
          <div class="sku-info-content">
            <p>{{item?.accQTY}}</p>
          </div>
        </div>
        <div class="sku-info-tb-wide pending-qty">
          <div class="sku-info-title">
            <p>펜딩 QTY</p>
          </div>
          <div class="sku-info-content">
            <p>{{item?.pendingQTY}}</p>
          </div>
        </div>
        <div class="sku-info-tb-wide pack-qty">
          <div class="sku-info-title">
            <p>포장 QTY</p>
          </div>
          <div class="sku-info-content">
            <p>{{item?.packQTY}}</p>
          </div>
        </div>
        <div class="sku-info-tb-wide temp-pack-qty">
          <div class="sku-info-title">
            <p>가포장 QTY</p>
          </div>
          <div class="sku-info-content">
            <p>{{item?.tempPackQTY}}</p>
          </div>
        </div>
        <div class="sku-info-tb-wide daily-output">
          <div class="sku-info-title">
            <p>일일 생산량</p>
          </div>
          <div class="sku-info-content">
            <p>{{item?.dailyOutput}}</p>
          </div>
        </div>
        <div class="sku-info-tb-wide hourly-target">
          <div class="sku-info-title">
            <p>시간당 TARGET</p>
          </div>
          <div class="sku-info-content">
            <p>{{item?.hourlyTarget}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-setting" [ngClass]="isSetting?'':'hidden'">
    <div class="top-area">
      <button class="save-btn" (click)="saveSetting(data.cardId)"></button>
      <button class="close-btn" (click)="closeSetting()"></button>
    </div>
    <div class="select-area">
      <select name="" [id]="'select-line'+data.cardId" [value]="curLine?curLine:''" class="selectbox-line">
        <option value="">라인을 선택하세요</option>
        <option value="LINE03">LINE 03</option>
        <option value="LINE05">LINE 05</option>
      </select>
    </div>
  </div>
</div>