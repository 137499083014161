import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { WildseaUiModule } from '@wildstorm/wildsea-ui';
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { AppRouting } from './app.routing';
import { AuthGuard } from './guard/auth.guard';
import { BackendProvider } from './utils/http-request-interceptor.service';
import {SimoneUiModule} from '@factoryflow/simone-ui';
import {LlangsongUiModule} from '@factoryflow/llangsong-ui';
@NgModule({
  declarations: [AppComponent, MainComponent],
  imports: [BrowserModule, WildseaUiModule, AppRouting,SimoneUiModule,LlangsongUiModule],
  providers: [BackendProvider,AuthGuard],
  bootstrap: [AppComponent],
})
export class AppModule {}
