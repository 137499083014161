import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlarmsAndEventsConfig } from '../app.config';
import { AuthService } from '../utils/auth.service';
import { LlangsongService } from 'libs/llangsong-ui/src/lib/services/llangsong.service';
@Component({
  selector: 'factoryflow-front-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent {

 @ViewChild('wildsea') wildsea;
 themeControlComponent
 realActiveComp
 user
 AlarmsAndEventsConfig = AlarmsAndEventsConfig;
 constructor(private translateService:TranslateService,  private authService: AuthService, private llangsongService : LlangsongService){
  this.user = this.authService.currentUserValue;
 }
  handleThemeAction(e){
   sessionStorage.setItem('theme',e) 
  this.wildsea.changeTheme()
  this.themeControlComponent.changeTheme();
  this.llangsongService.changeTheme();
  }
  setAuthCodeForPage(componentReference) {
    console.log("user  ==>", this.user);
    let authCode;
    if (this.user.auth_code == 'S' || this.user.auth_code == 'A') {
        authCode = 'AD';
    } else {
        authCode = 'OP';
    }
    if (componentReference.authCodeData) {
        componentReference.authCodeData(authCode);
    }
}
  onActivate(componentReference) {
    // 현재 라우팅된 컴포넌트 정보를 저장
    this.themeControlComponent = componentReference;
    if (componentReference.onComponentActiveAction) {
        this.setAuthCodeForPage(componentReference);
        componentReference.onComponentActiveAction.subscribe((data) => {
            this.realActiveComp = data;
        });
    }

    if (componentReference.onRequestAction) {
        componentReference.onRequestAction.subscribe((data) => {
          this.wildsea.onFilePathChanged(data);
        });
    }
}
  handleLanguageAction(e:string){
    sessionStorage.setItem('language',e)
    this.wildsea.changeTheme()
    this.themeControlComponent.changeTheme();
  }
}
