import { AfterContentChecked, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, Renderer2 } from '@angular/core';

@Component({
  selector: 'factoryflow-pop-over',
  templateUrl: './pop-over.component.html',
  styleUrls: ['./pop-over.component.scss']
})
export class PopOverComponent implements OnDestroy,AfterContentChecked {
  isPopoverOpen = false;
  copyCardData: any
  globalClickListener: any;
  @Output() iconOptionClick = new EventEmitter();
  @Output() iconFullScreenClick = new EventEmitter();
  @Output() iconSettingClick = new EventEmitter();
  @Output() iconChangeClick = new EventEmitter();
  @Output() iconCopyClick = new EventEmitter();
  @Output() iconPasteClick = new EventEmitter();
  @Output() iconDeleteClick = new EventEmitter();
  @Output() iconExportClick = new EventEmitter();
  @Input() iconDisableConfig?: IconDisableConfig
  @Input() dashboardState?: boolean;
  @Input() cardExtensionState?: boolean;

  constructor(private _elementRef: ElementRef, private renderer: Renderer2) { }

  ngAfterContentChecked() {
    this.copyCardData = sessionStorage.getItem('copyCardData');
  }

  ngOnDestroy() {
    this.removeGlobalClickListener();
  }

  togglePopover() {
    this.isPopoverOpen = !this.isPopoverOpen;
    if (this.isPopoverOpen) {
      this.globalClickListener = this.renderer.listen('document', 'click', (event) => {
        if (!this._elementRef.nativeElement.contains(event.target)) {
          this.isPopoverOpen = false;
          this.removeGlobalClickListener();
        }
      });
    } else {
      this.removeGlobalClickListener();
    }
  }

  private removeGlobalClickListener() {
    if (this.globalClickListener) {
      this.globalClickListener();
      this.globalClickListener = null;
    }
  }

  onIconOptionClick(event: Event) {
    this.iconOptionClick.emit(event);
  }

  onIconFullScreenClick() {
    this.iconFullScreenClick.emit();
  }

  onIconEditClick() {
    this.iconSettingClick.emit();
  }

  onIconChangeClick() {
    this.iconChangeClick.emit();
  }

  onIconCopyClick() {
    this.iconCopyClick.emit();
  }

  onIconPasteClick() {
    this.iconPasteClick.emit();
  }

  onIconDeleteClick() {
    this.iconDeleteClick.emit();
  }

  onIconExportClick() {
    this.iconExportClick.emit();
  }
}

export interface IconDisableConfig {
  isOption?: boolean;          // 옵션
  isSetting?: boolean;         // 설정
  isExcelExport?: boolean;     // 엑셀
};

