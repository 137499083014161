//Dark Theme
export const darkTheme = {
    lang: {
        numericSymbolMagnitude: 1000,
        numericSymbols: ["k", "M", "G", "T", "P", "E"]
    },
    colors: ['#64a0ff', '#a9d937', '#8259e4', '#37d2c5', '#ff9900', '#ff4163', '#d9b496', '#358165', '#a3724e', '#ff758f',
    '#364cb2', '#a39524', '#9e55b9', '#2584a7', '#bb8720', '#cd5e4e', '#938a7a', '#246a73', '#b6996c', '#ae4659'
    ],
    chart: {
        backgroundColor: '#252b34',
        style: {
            fontFamily: '\'Unica One\', sans-serif'
        },
        plotBorderColor: '#606063',
        plotBackgroundColor: '#252b34'
    },
    exporting: {
        enabled: false
    },
    title: {
        style: {
            color: '#E0E0E3',
            textTransform: 'uppercase',
            fontSize: '20px'
        }
    },
    subtitle: {
        style: {
            color: '#E0E0E3',
            textTransform: 'uppercase'
        }
    },
    xAxis: {
        gridLineColor: '#38404D',
        labels: {
            style: {
                color: '#E0E0E3'
            }
        },
        lineColor: '#38404D',
        minorGridLineColor: '#505053',
        tickColor: '#38404D',
        title: {
            style: {
                color: '#A0A0A3'

            }
        }
    },
    yAxis: {
        gridLineColor: '#38404D',
        labels: {
            style: {
                color: '#E0E0E3'
            },
            // format: "{value:f}"
        },
        lineColor: '#38404D',
        minorGridLineColor: '#505053',
        tickColor: '#38404D',
        tickWidth: 1,
        title: {
            style: {
                color: '#A0A0A3'
            }
        }
    },
    tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.85)',
        style: {
            color: '#F0F0F0'
        }
    },
    plotOptions: {
        series: {
            dataLabels: {
                color: '#B0B0B3'
            },
            marker: {
                lineColor: '#333'
            }
        },
        boxplot: {
            fillColor: '#505053'
        },
        candlestick: {
            upLineColor: 'white'
        },
        errorbar: {
            color: 'white'
        },
        column: {
            borderWidth: 0,
            dataLabels: {
                backgroundColor: '#1e222b'
            }
        },
        waterfall: {
            borderWidth: 0
        },
        bar: {
            borderWidth: 0
        },
        pie: {
            borderWidth: 0
        }
    },
    legend: {
        itemStyle: {
            color: '#E0E0E3'
        },
        itemHoverStyle: {
            color: '#FFF'
        },
        itemHiddenStyle: {
            color: '#606063'
        }
    },
    credits: {
        enabled: false
    },
    // labels: {
    //   style: {
    //         color: '#707073'
    //     }
    // },

    drilldown: {
        activeAxisLabelStyle: {
            color: '#F0F0F3'
        },
        activeDataLabelStyle: {
            color: '#F0F0F3'
        }
    },

    navigation: {
        buttonOptions: {
            symbolStroke: '#DDDDDD',
            theme: {
                fill: '#505053'
            }
        }
    },

    // scroll charts
    rangeSelector: {
        buttonTheme: {
            fill: '#505053',
            stroke: '#000000',
            style: {
                color: '#CCC'
            },
            states: {
                hover: {
                    fill: '#707073',
                    stroke: '#000000',
                    style: {
                        color: 'white'
                    }
                },
                select: {
                    fill: '#000003',
                    stroke: '#000000',
                    style: {
                        color: 'white'
                    }
                }
            }
        },
        inputBoxBorderColor: '#505053',
        inputStyle: {
            backgroundColor: '#333',
            color: 'silver'
        },
        labelStyle: {
            color: 'silver'
        }
    },

    navigator: {
        handles: {
            backgroundColor: '#666',
            borderColor: '#AAA'
        },
        outlineColor: '#CCC',
        maskFill: 'rgba(255,255,255,0.1)',
        series: {
            color: '#7798BF',
            lineColor: '#A6C7ED'
        },
        xAxis: {
            gridLineColor: '#505053'
        }
    },

    scrollbar: {
        barBackgroundColor: '#808083',
        barBorderColor: '#808083',
        buttonArrowColor: '#CCC',
        buttonBackgroundColor: '#606063',
        buttonBorderColor: '#606063',
        rifleColor: '#FFF',
        trackBackgroundColor: '#404043',
        trackBorderColor: '#404043'
    },

    // pane: {
    //     background: [{
    //         className: 'chart_background'
    //     }]
    // }
};

//White Theme
export const whiteTheme = {
    colors: ['#0f5fa5', '#00dacd', '#ff6781', '#ff9900', '#00a07c', '#1bc8e9', '#5b71ef', '#199bd5', '#9bdf4b', '#d3b59a',
    '#084a84', '#13a79e', '#cc4b78', '#663c90', '#e76700', '#0c7961', '#2095c6', '#4253ba', '#95af2c', '#a17954'
    ],
    chart: {
        backgroundColor: '#ffffff',
        style: {
            fontFamily: '\'Unica One\', sans-serif'
        },
        plotBorderColor: '#606063',
        plotBackgroundColor: '#ffffff'
    },
    exporting: {
        enabled: false
    },
    title: {
        style: {
            color: '#333333',
            textTransform: 'uppercase',
            fontSize: '20px'
        }
    },
    subtitle: {
        style: {
            color: '#666666',
            textTransform: 'uppercase'
        }
    },
    xAxis: {
        gridLineColor: '#e6e6e6',
        labels: {
            style: {
                color: '#666666'
            }
        },
        lineColor: '#ccd6eb',
        minorGridLineColor: '#f2f2f2',
        tickColor: '#ccd6eb',
        title: {
            style: {
                color: '#666666'

            }
        }
    },
    yAxis: {
        gridLineColor: '#e6e6e6',
        labels: {
            style: {
                color: '#666666'
            },
            // format: "{value:f}"
        },
        lineColor: '#ccd6eb',
        minorGridLineColor: '#f2f2f2',
        tickColor: '#ccd6eb',
        tickWidth: 1,
        title: {
            style: {
                color: '#666666'
            }
        }
    },
    tooltip: {
        backgroundColor: '#f7f7f7',
        style: {
            color: '#333333'
        }
    },
    plotOptions: {
        series: {
            dataLabels: {
                color: '#333333'
            },
            marker: {
                lineColor: '#335cad'
            }
        },
        boxplot: {
            fillColor: '#ffffff'
        },
        candlestick: {
           upLineColor: '#000000'
        },
        errorbar: {
            color: '#000000'
        },
        column: {
            borderWidth: 0,
            dataLabels: {
                backgroundColor: '#ffffff'
            }
        },
        waterfall: {
            borderWidth: 0
        },
        bar: {
            borderWidth: 0
        },
        pie: {
            borderWidth: 0
        }
    },
    legend: {
        itemStyle: {
            color: '#333333'
        },
        itemHoverStyle: {
            color: '#000000'
        },
        itemHiddenStyle: {
            color: '#cccccc'
        }
    },
    credits: {
        enabled: false
    },
    // labels: {
    //     style: {
    //         color: '#333333'
    //     }
    // },

    drilldown: {
        activeAxisLabelStyle: {
            color: '#333333'
        },
        activeDataLabelStyle: {
            color: '#333333'
        }
    },

    navigation: {
        buttonOptions: {
            symbolStroke: '#cccccc',
            theme: {
                fill: '#f7f7f7'
            }
        }
    },

    // scroll charts
    rangeSelector: {
        buttonTheme: {
            fill: '#f7f7f7',
            stroke: '#cccccc',
            style: {
                color: '#000000'
            },
            states: {
                hover: {
                    fill: '#e6e6e6',
                    stroke: '#333333',
                    style: {
                        color: '#333333'
                    }
                },
                select: {
                    fill: '#e6ebf5',
                    stroke: '#335cad',
                    style: {
                        color: '#333333'
                    }
                }
            }
        },
        inputBoxBorderColor: 'none',
        inputStyle: {
            backgroundColor: '#333333',
            color: '#cccccc'
        },
        labelStyle: {
            color: '#666666'
        }
    },

    navigator: {
        handles: {
            backgroundColor: '#f2f2f2',
            borderColor: '#AAA'
        },
        outlineColor: '#cccccc',
        maskFill: '#6685c2',
        series: {
            color: '#335cad',
            lineColor: '#A6C7ED'
        },
        xAxis: {
            gridLineColor: '#e6e6e6'
        }
    },

    scrollbar: {
        barBackgroundColor: '#cccccc',
        barBorderColor: '#cccccc',
        buttonArrowColor: '#666666',
        buttonBackgroundColor: '#e6e6e6',
        buttonBorderColor: '#cccccc',
        rifleColor: '#666666',
        trackBackgroundColor: '#f2f2f2',
        trackBorderColor: '#f2f2f2'
    },

    // pane: {
    //     background: [{
    //         className: 'chart_background'
    //     }]
    // }
};
