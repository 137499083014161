import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { appConfig } from '../utils/santamaria.config';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
 
    constructor( private router: Router ) { }
    

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        // if (this.$sessionStorage.retrieve('lsisjwt')) {
        if (sessionStorage.getItem("user") !== null) {
            // logged in so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;

    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        // if (this.$sessionStorage.retrieve('lsisjwt')) {
        if (appConfig.curUser !== '') {
            // logged in so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;

    }
}