import { HttpClient } from '@angular/common/http';
import { Injectable, SystemJsNgModuleLoaderConfig } from '@angular/core';
import { Subject } from 'rxjs';
const X_CERT_KEY = 'b0a1ce2d-5682-4243-a702-47e230bf3154';
const X_UUID = '6886c055-21da-43fc-a115-7446c4edb269';
const X_COMMANDTYPE = '81'
const COMPANYCD = '142-81-40232'
@Injectable({
  providedIn: 'root'
})
export class LlangsongService {
  themeSubject = new Subject();
  constructor(private http: HttpClient) {
   }
   getLlangsongData(type,date){
    const HEADER = {'x-certkey':X_CERT_KEY,'x-uuid':X_UUID,'x-commandtype':X_COMMANDTYPE}
    if(type === 'contentOutput'){
      return this.http.get(`/api/v1/statistics/production-item?date=${date}&company=${COMPANYCD}`,{headers:HEADER})
    }
    else if(type === 'productOutput'){
      return this.http.get(`/api/v1/statistics/daily-production?date=${date}&company=${COMPANYCD}`,{headers:HEADER})
    }
    else if(type === 'regionOrder'){
      return this.http.get(`/api/v1/statistics/order-qty?date=${date}&company=${COMPANYCD}`,{headers:HEADER})
    }
    else{//shippingAmount
      return this.http.get(`/api/v1/statistics/daily-outbound?date=${date}&company=${COMPANYCD}`,{headers:HEADER})
    }
   }
   changeTheme(){
    this.themeSubject.next(sessionStorage.getItem('theme'))
   }
}
