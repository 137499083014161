import { ProductionLineStatusComponent } from 'libs/simone-ui/src/lib/components/production-line-status/production-line-status.component';
import { ɵbt as AsteriskViewerComponent } from '@wildstorm/honeycomb-ui';
import { ɵk as FlexibleCardComponent } from '@wildstorm/honeycomb-ui';
import { ProductOutputComponent } from 'libs/llangsong-ui/src/lib/components/cards/product-output/product-output.component';
import { ShippingAmountComponent } from 'libs/llangsong-ui/src/lib/components/cards/shipping-amount/shipping-amount.component';
import { RegionOrderComponent } from 'libs/llangsong-ui/src/lib/components/cards/region-order/region-order.component';
import { ContentOutputComponent } from 'libs/llangsong-ui/src/lib/components/cards/content-output/content-output.component';
import { ProductionStatusDayComponent } from 'libs/llangsong-ui/src/lib/components/cards/production-status-day/production-status-day.component';
// import { ɵcg as AlarmEventComponent } from '@wildstorm/honeycomb-ui';
// import { ɵbu as TodoListOfTodayComponent } from '@wildstorm/honeycomb-ui';
/** Routes Data Set */
export const RoutesConfig = {
  menuInfo: [
    {
      url: '/rainbow',
      name: 'rainbow',
      displayName: { default: '트렌드', ko: '', en: '', zh: '' },
      singleType: false,
      property: [{ name: 'filepath', type: 'string' }],
    },
    {
      url: '/asterisk',
      name: 'asterisk',
      displayName: { default: '웹작화', ko: '', en: '', zh: '' },
      singleType: false,
      property: [{ name: 'filepath', type: 'string' }],
    },
    // {
    //   url: '/crosswind',
    //   name: 'crosswind',
    //   displayName: { default: '스크립트', ko: '', en: '', zh: '' },
    //   singleType: false,
    //   property: [],
    // },
    {
      url: '/honeycomb',
      name: 'honeycomb',
      displayName: { default: '대시보드', ko: '', en: '', zh: '' },
      singleType: false,
      property: [{ name: 'filepath', type: 'string' }],
    },
    // {
    //   url: '/zephyr',
    //   name: 'zephyr',
    //   displayName: { default: '알람/이벤트', ko: '', en: '', zh: '' },
    //   singleType: false,
    //   property: [],
    // },
    // singleType true ======================================================
    {
      url: '/wildgarden/user-management',
      name: 'user',
      displayName: { default: '사용자 관리', ko: '', en: '', zh: '' },
      singleType: true,
      property: [],
    },
    {
      url: '/wildgarden/menu-management',
      name: 'menu',
      displayName: { default: '메뉴 관리', ko: '', en: '', zh: '' },
      singleType: true,
      property: [],
    },
    {
      url: '/wildgarden/site-management',
      name: 'site',
      displayName: { default: '사이트 관리', ko: '', en: '', zh: '' },
      singleType: true,
      property: [],
    },
    {
      url: '/kpi/board-setting',
      name: 'kpi',
      displayName: { default: 'KPI', ko: '', en: '', zh: '' },
      singleType: true,
      property: [],
    },
    // {
    //   url: '/schedule',
    //   name: 'schedule',
    //   displayName: { default: '일정관리', ko: '', en: '', zh: '' },
    //   singleType: true,
    //   property: [],
    // },
  ],
};
export const ComponentInfoConfig = [
  {
    displayName: { default: 'Simone', ko: '', en: '', zh: '' },
    children: [
      {
        comps: ProductionLineStatusComponent,
        displayName: {
          default: 'Production Line Status',
          ko: '',
          en: '',
          zh: '',
        },
      },
    ],
  },
  {
    displayName: { default: 'Llangsong', ko: '', en: '', zh: '' },
    children: [
      {
        comps: ProductionStatusDayComponent,
        displayName: {
          default: '일 주문 생산 현황',
          ko: '',
          en: '',
          zh: '',
        },
      },
    ],
  },
      {displayName: {default: '사용자 정의', ko: '', en: '', zh: ''},
      children:[
          {
              comps: FlexibleCardComponent,
              displayName: {default: '사용자 정의 카드', ko: '', en: '', zh: ''},
          }
      ]
    },
    {displayName: {default: '웹 작화', ko: '', en: '', zh: ''},
      children:[
          {
              comps: AsteriskViewerComponent,
              displayName: {default: '작화', ko: '', en: '', zh: ''},
          }
      ]
    },
    // {displayName: {default: '알람/이벤트', ko: '', en: '', zh: ''},
    //   children:[
    //       {
    //           comps: AlarmEventComponent,
    //           displayName: {default: '알람/이벤트', ko: '', en: '', zh: ''},
    //       }
    //   ]
    // }, 
    // {displayName: {default: '일정 관리', ko: '', en: '', zh: ''},
    //   children:[
    //       {
    //           comps: TodoListOfTodayComponent,
    //           displayName: {default: '오늘의 To-Do', ko: '', en: '', zh: ''},
    //       }
    //   ]
    // }
];
export const AlarmsAndEventsConfig = {
  isAlarm: false,
  isEvents: false,
}