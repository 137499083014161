import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpEvent, HttpHeaders, HttpErrorResponse, HttpResponse, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { User } from './user';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
// Setting token when calling API
export class HttpRequestInterceptorService  implements HttpInterceptor {

    constructor(
        private authService: AuthService
      ) {

      }


    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.indexOf('assets/i18n') > -1 || req.url.indexOf('wildsea/v1/header') > -1 || req.url.indexOf('assets/wildsea/i18n') > -1) {
            return next.handle(req);
        } else {
            if(req.url.indexOf( '/signin' ) < 0 ) {

                const currentUser: User|null = this.authService.currentUserValue;
                const authToken = currentUser?.auth_token;
                if ( authToken ) {

                    req = req.clone(
                        { headers: req.headers.set('authtoken', authToken) }
                    );
                    // Update user information when generating refresh tokens
                    if ( authToken !== req.headers.get('authtoken') ) {
                        if(currentUser) {
                            currentUser.auth_token = req.headers.get('authtoken') as string;
                            
                        }
                    }
                }
            }
        }
        return next.handle(req).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    if (event.headers.get('authtoken')) {

                        let currentUser: User |null = this.authService.currentUserValue;

                        currentUser =
                        {
                            ...currentUser as User,
                            auth_token: event.headers.get('authtoken') as string
                        };

                        this.authService.setUser(currentUser);
                    }
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                // Error handling
                this.handleError(error);
                return throwError(error);
            })
        );
    }

    private handleError(error: HttpErrorResponse) {
        // 401 unauthorized response so log user out of client
        if (error.status === 401) {
            // token init
            this.authService.removeUser();

            if (window.location.href.search('/signin') < 0) {
                window.location.reload();
            }

        } else{
            console.log(error.headers);

            if(error.headers.get('resultCode') !=='9101'){
                const msg = error.headers.get('resultMessage') === null ? 'No Response Services.' : error.headers.get('resultMessage');
                console.error('[ERROR] ' + msg);
            }
        }
    }
}

export const BackendProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpRequestInterceptorService,
    multi: true
}
