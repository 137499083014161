import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import * as Highcharts from 'highcharts';
import { darkTheme, whiteTheme } from '../production-status-day/const/chart.themes';
import { LlangsongService } from '../../../services/llangsong.service';
@Component({
  selector: 'factoryflow-shipping-amount',
  templateUrl: './shipping-amount.component.html',
  styleUrls: ['./shipping-amount.component.scss']
})
export class ShippingAmountComponent implements OnInit , AfterViewInit,OnChanges,OnDestroy {
  @Input() llangsongData;
  @ViewChild('shippingChart') shippingChart: ElementRef;
  options;
  chart;
  chartData;
  themeSub;
  constructor(private llansongService : LlangsongService) { }

  ngOnInit(): void {
    sessionStorage.getItem('theme') === 'dark' ? Highcharts.setOptions(darkTheme) : Highcharts.setOptions(whiteTheme)
    this.themeSub = this.llansongService.themeSubject.subscribe({
      next: (value)=>{
        console.log(value);
        if(value==='dark'){
          Highcharts.setOptions(darkTheme)
        }else{
          Highcharts.setOptions(whiteTheme)
        }
        this.chart = Highcharts.chart(this.shippingChart.nativeElement, this.options);
      }
    })
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.llangsongData.currentValue){
      this.chartData = changes.llangsongData.currentValue.daliyOutboundList
        this.updateChartData();
    }
}
  ngAfterViewInit(): void {
    this.initChartOption()
      
  }
  ngOnDestroy(): void {
      this.themeSub.unsubscribe();
  }
  updateChartData(){
    const seriesData = this.chartData.map((item) => ({data:[{name:item.date,y:Number(item.outboundCnt)}],name:item.date}))
    for(let i=0; i<seriesData.length; i++){
      if(this.chart.series[i]){
        this.options.series[i] = {data:seriesData[i].data,name:seriesData[i].name}
        this.chart.series[i].update({ data: seriesData[i].data, name: seriesData[i].name }, false);
      }else{
        this.chart.addSeries({ data: seriesData[i].data, name: seriesData[i].name});
        this.options.series.push({data:seriesData[i].data,name:seriesData[i].name})
      }
    }
   this.chart.redraw();
  }
  initChartOption() {
    this.options = {
      chart: {
        type: 'column',
        spacingTop: 10,
        spacingBottom: 0,
        spacingLeft: 0,
        spacingRight: 0,
        style: {
          fontFamily: 'NotoSansCJKkr, NotoSansKR, sans-serif'
        }
      },
      title: {
        text: null
      },
      yAxis: {
        tickLength: 0,
        title: {
          text: null
        },
        labels: {
          x: -6,
          style: {
            fontSize: '12px',
            fontWeight: 350,
          }
        }
      },
      
      xAxis: {
        categories: ['출하수량'],
        labels: {
          style: {
            fontSize: '12px',
            fontWeight: 400,
          }
        }
      },
      plotOptions: {
        column: {
          dataLabels: {
            useHTML: true,
            align: 'center',
            allowOverlap: true,
            verticalAlign: 'top',
            y: -20
          }
        }
      },
      legend: {
        symbolWidth: 10,
        symbolHeight: 10,
        symbolPadding: 5,
        margin: 5,
        itemStyle: { "fontSize": "14px", "fontWeight": "400" },
        itemMarginTop: 2,
        itemMarginBottom: 2,
        maxHeight: 70,
        navigation: {
          activeColor: '#3E576F',
          animation: true,
          arrowSize: 8,
          inactiveColor: '#CCC',
          style: {
            fontSize: '12px'
          }
        }
      },
      tooltip: {
        enabled: true,
        shadow: false,
        formatter: function () {
          const self : any = this
          return '<b>' + self.series.name+' : ' + '</b>' + self.y + 'EA';
        }
      },
      navigation:{
        buttonOptions:{
          enabled:false
        }
      },
      series: [],
      credits: {
        enabled: false
      }
    };

    this.chart = Highcharts.chart(this.shippingChart.nativeElement, this.options);
  }
}
