<div class="icon-wrap">
    <span class="ic ic-option" *ngIf="iconDisableConfig?.isOption" (click)="onIconOptionClick($event)" [ngClass]="dashboardState?'hidden':''"></span>
  
    <!-- View Mode -->
    <ng-container *ngIf="!dashboardState">
      <span *ngIf="!cardExtensionState" class="ic ic-fullscreen fullscreen-class disable"
        (click)="onIconFullScreenClick()"></span>
      <span *ngIf="cardExtensionState" class="ic ic-fullscreenclose fullscreen-class disable"
        (click)="onIconFullScreenClick()"></span>
      <span class="ic ic-meatballs" (click)="togglePopover()"></span>
      <div *ngIf="isPopoverOpen" class="icon-popover" (click)="togglePopover()">
        <div class="disable" (click)="onIconEditClick()">
          <span class="ic ic-edit"></span>
          <span>카드 설정</span>
        </div>
        <div class="disable" (click)="onIconChangeClick()">
          <span class="ic ic-change"></span>
          <span>카드 변경</span>
        </div>
        <div class="disable" (click)="onIconCopyClick()">
          <span class="ic ic-copy"></span>
          <span>카드 복사</span>
        </div>
        <div class="disable" (click)="onIconPasteClick()">
          <span class="ic ic-paste"></span>
          <span>카드 붙여넣기</span>
        </div>
        <div class="disable" (click)="onIconDeleteClick()">
          <span class="ic ic-delete btn-del-card"></span>
          <span>카드 삭제</span>
        </div>
        <div [class.disable]="!iconDisableConfig?.isExcelExport" (click)="onIconExportClick()">
          <span class="ic ic-export"></span>
          <span>엑셀 추출</span>
        </div>
      </div>
    </ng-container>
  
  
    <!-- Edit Mode -->
    <ng-container *ngIf="dashboardState">
      <span class="ic ic-fullscreen disable" (click)="onIconFullScreenClick()"></span>
      <span class="ic ic-meatballs" (click)="togglePopover()"></span>
      <div *ngIf="isPopoverOpen" class="icon-popover" (click)="togglePopover()">
        <div [class.disable]="!iconDisableConfig?.isSetting" (click)="onIconEditClick()">
          <span class="ic ic-edit"></span>
          <span>카드 설정</span>
        </div>
        <div (click)="onIconChangeClick()">
          <span class="ic ic-change"></span>
          <span>카드 변경</span>
        </div>
        <div (click)="onIconCopyClick()">
          <span class="ic ic-copy"></span>
          <span>카드 복사</span>
        </div>
        <div (click)="onIconPasteClick()" [ngClass]="{'disable': copyCardData === null}">
          <span class="ic ic-paste"></span>
          <span>카드 붙여넣기</span>
        </div>
        <div (click)="onIconDeleteClick()">
          <span class="ic ic-delete btn-del-card"></span>
          <span>카드 삭제</span>
        </div>
        <div [class.disable]="!iconDisableConfig?.isExcelExport" (click)="onIconExportClick()">
          <span class="ic ic-export"></span>
          <span>엑셀 추출</span>
        </div>
      </div>
    </ng-container>
  
  
  </div>